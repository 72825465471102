import React from "react"
import styles from "./matterport.module.scss"

export default () => (
  <div className={styles.matterport}>
    <iframe
      width="581"
      height="397"
      src="https://my.matterport.com/show/?m=pyrem1ErMWD"
      frameBorder="0"
      allowFullScreen
      title="Matterport"
    ></iframe>
  </div>
)
